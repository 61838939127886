<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('elective_pools_title')" :isColumns="true"
                    :isNewButton="checkPermission('electivepool_store')" @new-button-click="createFormShow"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('elective_pools_title')" :isColumns="true"
                    :isNewButton="checkPermission('electivepool_store')" @new-button-click="createFormShow"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                :export-excel="checkPermission('electivepool_excelexport')" @exportExcel="downloadExcel">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('id')">
                            <b-form-input type="number" size="sm" v-model="datatable.queryParams.filter.id"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('department')">
                            <department-selectbox v-model="datatable.queryParams.filter.department_code"
                                :faculty_code="datatable.queryParams.filter.faculty_code"></department-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                :department_code="datatable.queryParams.filter.department_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('slot_type')">
                            <slot-type-selectbox v-model="datatable.queryParams.filter.type"></slot-type-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('status')">
                            <status-selectbox v-model="datatable.queryParams.filter.status"></status-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>
            <CommonModal size="xl" ref="defineModal" @bHideModalHeaderClose="closeDefineModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('elective_pools_form').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <define-form v-if="createFormStatus" @hide="closeDefineModal"></define-form>
                    </div>
                </template>
            </CommonModal>
            <CommonModal size="xl" ref="editModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('elective_pools_update').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <update-form :id="selectedId" @hide="closeEditModal"></update-form>
                    </div>
                </template>
            </CommonModal>
            <CommonModal size="xl" ref="coursesModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('elective_pools_title').toUpper() + ' ' + $t('courses') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <courses :id="selectedId" @hide="closeCoursesModal"></courses>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import Datatable from "@/components/datatable/Datatable";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import qs from "qs";
import CommonModal from "@/components/elements/CommonModal";
import UpdateForm from "./updateForm";
import DefineForm from "./defineForm";
import ElectivePoolService from "@/services/ElectivePoolService";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import SlotTypeSelectbox from "@/components/interactive-fields/SlotTypeSelectbox";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import Courses from "./Courses";
import ExamScheduleService from "@/services/ExamScheduleService";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        Datatable,
        DatatableFilter,
        CommonModal,
        UpdateForm,
        DefineForm,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        SlotTypeSelectbox,
        StatusSelectbox,
        Courses
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-pencil-fill',
                                permission: "electivepool_update",
                                callback: (row) => {
                                    this.selectedId = row.id
                                    this.$refs.editModal.$refs.commonModal.show()
                                }
                            },
                            {
                                text: this.$t('courses'),
                                class: 'ri-book-mark-fill',
                                permission: "electivecourse_index",
                                callback: (row) => {
                                    this.selectedId = row.id
                                    this.$refs.coursesModal.$refs.commonModal.show()
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line',
                                permission: "electivepool_delete",
                                callback: (row) => {
                                    this.deleteItem(row)
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('id').toUpper(),
                        field: 'id',
                        hidden: false,
                    },
                    {
                        label: this.$t('pool_name').toUpper(),
                        field: ('name'),
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('faculty').toUpper(),
                        field: ('faculty_name'),
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('faculty_code').toUpper(),
                        field: 'faculty_code',
                        hidden: true,
                        sortable: false
                    },
                    {
                        label: this.$t('department').toUpper(),
                        field: ('department_name'),
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('department_code').toUpper(),
                        field: 'department_code',
                        hidden: true,
                        sortable: false
                    },
                    {
                        label: this.$t('program').toUpper(),
                        field: ('program_name'),
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('program_code').toUpper(),
                        field: 'program_code',
                        hidden: true,
                        sortable: false
                    },
                    {
                        label: this.$t('slot_type').toUpper(),
                        field: ('type_name'),
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('slot_type_code').toUpper(),
                        field: 'type',
                        hidden: true,
                        sortable: false
                    },
                    {
                        label: this.$t('status').toUpper(),
                        field: 'status',
                        hidden: false,
                        sortable: false,
                        formatFn: (row) => {
                            if (row == 'a') {
                                return this.$t('active')
                            } else {
                                return this.$t('passive')
                            }
                        }
                    }

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            selectedId: null,
            createFormStatus: false
        }
    },
    metaInfo() {
        return {
            title: this.$t("elective_pools_title")
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },

        filterClear() {
            this.filterSet()
            this.getRows();
        },

        filterSet() {
            this.datatable.queryParams.filter = {
                id: null
            };
        },

        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },

        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },

        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },

        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },

        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return ElectivePoolService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                }).finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        deleteItem(row) {
            this.$swal({
                title: this.$t('attention'),
                text: this.$t('elective_pools_delete_confirm_text'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then(response => {
                if (response.isConfirmed == true) {
                    ElectivePoolService.remove(row.id).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getRows()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    })
                }
            })
        },

        closeEditModal() {
            this.$refs.editModal.$refs.commonModal.hide()
            this.getRows()
        },

        closeDefineModal() {
            this.createFormStatus = false
            this.$refs.defineModal.$refs.commonModal.hide()
            this.getRows()
        },

        closeCoursesModal() {
            this.$refs.coursesModal.$refs.commonModal.hide()
        },
        downloadExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            ExamScheduleService.downloadAsExcel(config)
                .then(res => this._downloadFile(res, this.$t("elective_pools_title") + '.xlsx'))
                .catch(err => this.showErrors(err))

        },

        createFormShow() {
            this.createFormStatus = true
            this.$refs.defineModal.$refs.commonModal.show()
        }
    },

    created() {
        this.filterSet()
    },
};
</script>
