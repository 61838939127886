<template>
    <div>
        <div class="row data-form">
            <div class="col-12">
                <div class="mb-4">
                    <h6 class="text-uppercase mb-0">{{$t('elective_pools_info').toUpper()}}</h6>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('pool_name')">
                                    <b-form-input
                                        v-model="defineForm.name"
                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('slot_type')">
                                    <slot-type-selectbox
                                        :validateError="errors[0]"
                                        v-model="defineForm.type"
                                        nonFilter="course">
                                    </slot-type-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="facultyCode" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('faculty')">
                                    <faculty-selectbox
                                        :validateError="errors[0]"
                                        v-model="defineForm.facultyCode">
                                    </faculty-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="departmentCode" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('department')">
                                    <department-selectbox
                                        :validateError="errors[0]"
                                        v-model="defineForm.departmentCode"
                                        :faculty_code="defineForm.facultyCode">
                                    </department-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="programCode" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('program')">
                                    <program-selectbox
                                        :validateError="errors[0]"
                                        v-model="defineForm.programCode"
                                        :faculty_code="defineForm.facultyCode"
                                        :department_code="defineForm.departmentCode">
                                    </program-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('status')">
                                    <status-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.status">
                                    </status-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>




                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import ElectivePoolService from '@/services/ElectivePoolService';
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
    import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox';
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';
    import SlotTypeSelectbox from '@/components/interactive-fields/SlotTypeSelectbox';
    import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';

    export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        FacultySelectbox,
        ProgramSelectbox,
        DepartmentSelectbox,
        SlotTypeSelectbox,
        StatusSelectbox
    },
    props: {
        id: {
            default: null,
            type: Number
        }
    },
    data() {
        return {
            defineForm: {
                name: null,
                nameEn: null,
                facultyCode: null,
                departmentCode: null,
                programCode: null,
                type: null,
                status: null
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("elective_pools_title")
        }
    },
    methods: {
        getFormData() {
            ElectivePoolService.show(this.id)
                               .then(response => {
                                   let data = response.data.data
                                   this.defineForm.name = data.name

                                   setTimeout(() => {
                                       this.defineForm.facultyCode = data.faculty_code
                                   }, 500)
                                   setTimeout(() => {
                                       this.defineForm.departmentCode = data.department_code
                                   }, 1000)
                                   setTimeout(() => {
                                       this.defineForm.programCode = data.program_code
                                   }, 1500)

                                   this.defineForm.type = data.type
                                   this.defineForm.status = data.status
                               })
        },

        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let formData = {
                    name: this.defineForm.name,
                    faculty_code: this.defineForm.facultyCode,
                    department_code: this.defineForm.departmentCode,
                    program_code: this.defineForm.programCode,
                    type: this.defineForm.type,
                    status: this.defineForm.status
                }

                ElectivePoolService.update(this.id, formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('hide')
                }).catch(e => {
                    if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$refs.updateForm.errors.name.push(this.$t('api.' + e.data.message));
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.name) {
                            this.$refs.updateForm.errors.name.push(e.data.errors.name[0]);
                        }
                        if (e.data.errors.faculty_code) {
                            this.$refs.updateForm.errors.facultyCode.push(e.data.errors.faculty_code[0]);
                        }
                        if (e.data.errors.department_code) {
                            this.$refs.updateForm.errors.departmentCode.push(e.data.errors.department_code[0]);
                        }
                        if (e.data.errors.program_code) {
                            this.$refs.updateForm.errors.programCode.push(e.data.errors.program_code[0]);
                        }
                        if (e.data.errors.type) {
                            this.$refs.updateForm.errors.type.push(e.data.errors.type[0]);
                        }
                        if (e.data.errors.status) {
                            this.$refs.updateForm.errors.status.push(e.data.errors.status[0]);
                        }
                    }
                })
            }
        }
    },

    created() {

    },

    watch: {
        id: {
            handler: function (val) {
                this.getFormData(val)
            }
        }
    }
};
</script>
